'use client'

import { Button } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'

import ProgressLoader from '@/components/ProgressLoader'

import { logoLoginClx } from '@/branding-config'
import { API_URL } from '@/constants/env'
import { cn } from '@/utils/clsx'

import companyLogo from '../../../public/companyLogo.png'
import loginBackground from '../../../public/loginBackground.png'

const LoginPage = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { loading, startLoading } = useLoadingState()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (user) {
      const redirect = searchParams ? searchParams[1] : '/admin/dashboard'
      router.replace(redirect)
      startLoading()
    }
  }, [user])

  const login = () => {
    const redirect =
      searchParams && searchParams[0] !== 'error'
        ? searchParams[1]
        : '/admin/dashboard'
    router.push(
      `${API_URL}/auth/login?redirect=${encodeURIComponent(redirect)}`
    )
    startLoading()
  }

  return (
    <>
      {loading && <ProgressLoader />}
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl border border-on-surface/20 bg-surface dark:border-dark-on-surface/20 dark:bg-dark-surface'>
        <div className='relative flex h-[25em] w-[90vw] flex-col gap-8 sm:w-[33em]'>
          <Image
            src={companyLogo}
            alt='Company logo'
            className={cn(
              'absolute left-1/2 top-1/4 z-50 -translate-x-1/2 px-2',
              logoLoginClx
            )}
          />
          <section>
            <Image
              src={loginBackground}
              alt='Login Background'
              className='flex h-48 justify-center rounded-t-md object-cover opacity-80'
            />
            <div className='to-transparent absolute top-0 h-48 w-full rounded-t-md bg-gradient-to-t from-surface opacity-100 dark:from-dark-surface'></div>
          </section>
          <section className='flex flex-col items-center gap-5 px-2'>
            <div className='flex flex-col gap-2 text-on-surface dark:text-dark-on-surface'>
              <h3 className='text-lg font-bold'>{t('login-page-welcome')}</h3>
              <p>{t('login-page-instructions')}</p>
            </div>
            <Button
              type='primary'
              onClick={login}
              className='w-full text-on-surface sm:w-40 dark:text-dark-on-surface'
            >
              {t('login-page-sign-in')}
            </Button>
            <p className='text-sm text-on-surface dark:text-dark-on-surface'>
              {t('sign-up-no-account-yet')}{' '}
              <Link
                href='/signup'
                className='font-semibold underline hover:opacity-80'
              >
                {t('sign-up')}
              </Link>
            </p>
          </section>
        </div>
      </div>
    </>
  )
}

export default LoginPage
